import './App.css';
import { useEffect, useRef, useState } from 'react';

const googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.sarchive.knowd';
const appStoreUrl = 'https://apps.apple.com/kr/app/knowd/id6446268083';
const pathname = window.location.pathname;
const appLink = `com.sarchive.knowd://onlink.knowd.art${pathname}`;

var userAgent = navigator.userAgent;
var visitedAt = (new Date()).getTime(); // 방문 시간
//if user is Chrome on Android using intent link
//else using iframe trick
const isChrome = /Chrome/.test(userAgent);
const isKakao = /KAKAOTALK/.test(userAgent);
const isAndroid = /Android/.test(userAgent);
const isIOS = /iPhone|iPad|iPod/.test(userAgent);
const isIOSChrome = /CriOS/.test(userAgent);
const isSafari = /Safari/.test(userAgent);
const isMobile = /Android|iPhone|iPad|iPod/.test(userAgent);
if (isMobile) {
  if (isAndroid) {
    //if Chrome & Android
    //open intent link
    // setTimeout(() => {
    //   if ((new Date()).getTime() - visitedAt < 2000) {
    //     window.location.href = googlePlayUrl;
    //   }
    // }, 500);
    // if (isChrome) {
    //   setTimeout(() => {
    //     window.location.href = `intent://onlink.knowd.art${pathname}#Intent;scheme=https;end;`;
    //   }, 25);
    // } else {
    // var iframe = document.createElement('iframe');
    // iframe.style.visibility = 'hidden';
    // iframe.src = appLink;
    // document.body.appendChild(iframe);
    // document.body.removeChild(iframe);
    // }
    if (isKakao) {
      setTimeout(() => {
        window.location.href = `intent://onlink.knowd.art${pathname}#Intent;scheme=https;end;`;
      }, 25);
    } else if (isChrome) {
      //in Chrome, App Link works well so there is no code for open app
      setTimeout(() => {
        if ((new Date()).getTime() - visitedAt < 2000) {
          window.location.href = googlePlayUrl;
        }
      }, 500);
    } else {
      var iframe = document.createElement('iframe');
      iframe.style.visibility = 'hidden';
      iframe.src = appLink;
      document.body.appendChild(iframe);

      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 1000);
    }
  } else if (isIOS) {
    if (isKakao) {
      setTimeout(() => {
        if ((new Date()).getTime() - visitedAt < 2000) {
          window.location.href = appStoreUrl;
        }
      }, 500);
      window.location.href = appLink;
    } else if (isIOSChrome) {
      setTimeout(() => {
        if ((new Date()).getTime() - visitedAt < 2000) {
          window.location.href = appStoreUrl;
        }
      }, 500);
      // window.location.href = appLink;
    } else if (isSafari) {
      setTimeout(() => {
        if ((new Date()).getTime() - visitedAt < 2000) {
          window.location.href = appStoreUrl;
        }
      }, 500);
      // window.location.href = appLink;
    }
  }
}


function App() {
  const btn = useRef(null);
  return (
    <div className="App">
      <header className="App-header">
        {/* Row contain GooglePlaystore btn and AppStore btn*/}
        {/* Btn image is square type 300 * 300 */}
        <div className="row">
          {isAndroid &&
            <a href={`${googlePlayUrl}&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1`}
              style={{ display: 'inline-block', overflow: 'hidden', borderRadius: '13px', width: '250px', height: '83px' }}>
              <img alt='다운로드하기 Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/ko_badge_web_generic.png'
                style={{
                  width: '100%', // 이미지 너비를 부모 요소에 맞춤
                  height: '100%', // 이미지 높이를 부모 요소에 맞춤
                  objectFit: 'fill' // 이미지가 부모 요소 내에 맞게 표시되도록 설정
                }} />
            </a>}
          {isIOS &&
            <a href="https://apps.apple.com/kr/app/knowd/id6446268083?itsct=apps_box_badge&itscg=30200"
              style={{ display: 'inline-block', overflow: 'hidden', borderRadius: '13px', width: '250px', height: '83px' }}>
              <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/ko-kr?size=250x83&amp;releaseDate=1680825600"
                alt="Download on the App Store"
                style={{ border: 'none', width: '250px', height: '83px' }} />
            </a>}
        </div>
        {/* button which open app deeplink */}
        <button onClick={() => {
          btn.current.click();
        }}>클릭하여 Knowd 열기</button>
        <a ref={btn} href={appLink} />
      </header>
    </div>
  );
}

export default App;
